import React, { useEffect } from "react"
import { graphql } from "gatsby"

// GQL Fragments
import { QueryFragments } from "../GraphQL/queryFragments" //eslint-disable-line

// Hooks, Querys & State
import { useAppState } from "../state/appState"

import { PageMeta } from "../components/pageMeta"
import BlockZone from "../components/blocks/blockZone"
import { ProjectBanner, RelatedProjects } from "../components/blocks"
import { ScrollProgress } from "../components/ui"
import { CtaContent } from "../components/ui"


const Project = ({ data: { page, relatedPosts } }) => {

  const { meta, pageBlocks, portalImage, featuredHeading, minorHeading, mainHeading } =
    page || {}   

  const {
    setGlobalTheme,
    toggleHideHeader,
    toggleHideFooter,
    toggleHeaderUnderline,
  } = useAppState()

  useEffect(() => {
    setGlobalTheme("light")
    toggleHideFooter(false)
    toggleHideHeader(true)
    toggleHeaderUnderline(false)
  }, [
    setGlobalTheme,
    toggleHideHeader,
    toggleHideFooter,
    toggleHeaderUnderline,
  ])

  return (
    <div id="progress-wrapper" className="">
      <PageMeta {...meta} />
      <ScrollProgress target={`/projects`} />
      <ProjectBanner
        mainHeading={mainHeading}
        minorHeading={minorHeading}
        image={portalImage}
        featureHeading={featuredHeading}
      />
      {pageBlocks && <BlockZone {...pageBlocks} />}
      <RelatedProjects items={relatedPosts} />
      <CtaContent spacing={{ padTop: '3', padBot: '3' }} />
    </div>
  );
}

export default Project

export const pageQuery = graphql`
  query projectQuery($slug: String!) {
    page: sanityProject(main: { slug: { current: { eq: $slug } } }) {
      pageBlocks {
        ...BlockZoneBlocks
      }
      featuredHeading
      minorHeading
      mainHeading: _rawMainHeading(resolveReferences: { maxDepth: 10 })
      main {
        title
      }
      portalImage {
        image {
          ...ImageWithPreview
        }
      }
      meta {
        ...MetaCardFields
      }
    }
    relatedPosts: allSanityProject(
      filter: { main: { slug: { current: { ne: $slug } } } }
      limit: 10
    ) {
      edges {
        node {
          main {
            title
            slug {
              current
            }
          }
          portalImage {
            image {
              ...ImageWithPreview
            }
          }
        }
      }
    }
  }
`;
